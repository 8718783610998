import * as React from "react";
import Layout from "@src/components/templates/layout";
import Seo from "@src/components/seo";
import Creation from "@src/components/pages/creation";

const CreationPage = () => {
    return(
        <Layout>
            <Seo title="創造事業部" />
            <Creation />
        </Layout>
    )
}

export default CreationPage