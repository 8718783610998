import * as React from "react"
import Accordion from "@src/components/atoms/accordion/index"
import ContactInfo from "@src/components/molecules/contact-info/index"
import Divisions from "@src/components/molecules/divisions/index"
import MainStage from "@src/components/molecules/main-stage/index"
import PageHeader from "@src/components/molecules/page-header/index"
import Works from "@src/components/molecules/works/index"

import { Up } from "@src/components/molecules/fadein/index"
import { CreationScrollDescription } from "@src/components/molecules/page-scroll-description/index"

const Creation = () => {
    /**
     * @type {React.MutableRefObject<HTMLVideoElement>}
     */
    const videoRef = React.useRef(null)

    React.useEffect(() => {
        const timer = setInterval(() => {
            videoRef.current.pause();
            const duration = videoRef.current.duration;
            if (isNaN(duration)) return
            if (window.scrollY + window.innerHeight < window.innerHeight * 2.5) {
                videoRef.current.currentTime = (duration * window.scrollY) / (window.innerHeight * 2.5 - window.innerHeight)
                videoRef.current.style.position = "fixed";
                videoRef.current.style.top = "61px"
            } else {
                videoRef.current.currentTime = 0;
                videoRef.current.style.position = "sticky";
                videoRef.current.style.top = "61px";
            }
        }, 100)

        return () => {
            clearInterval(timer)
        }
    }, [])

    return(
        <article className="creation">
            <PageHeader title="創造事業部">
                <CreationScrollDescription />
                <video src="https://dl5km2ob7ccxv.cloudfront.net/main/diamond.mp4" autoPlay muted playsInline style={{ width: "100%"}} ref={videoRef}>
                    <p>動画の再生に対応していません</p>
                </video>
            </PageHeader>

            <section className="page-section">
                <div className="l-contents-padding">
                    <Up>
                        <h3 className="page-section-title">Service</h3>
                    </Up>
                        <div className="accordion-wrap">
                            <Up>
                                <Accordion title="WEBデザイン" id="web-design">
                                    <li className="accordion-item">ホームページ制作</li>
                                    <li className="accordion-item">ECサイト制作</li>
                                    <li className="accordion-item">ロゴマーク制作</li>
                                </Accordion>
                            </Up>
                            <Up>
                                <Accordion title="CM(動画)制作" id="movie">
                                    <li className="accordion-item">動画広告撮影/制作</li>
                                    <li className="accordion-item">Youtube広告</li>
                                </Accordion>
                            </Up>
                            <Up>
                                <Accordion title="印刷物制作" id="printed-matter">
                                    <li className="accordion-item">名刺デザイン制作</li>
                                    <li className="accordion-item">メニュー表制作</li>
                                </Accordion>
                            </Up>
                        </div>
                </div>
            </section>

            <Works />
            <MainStage />
            <Divisions page="creation" />
            <ContactInfo />
        </article>
    )
}

export default Creation